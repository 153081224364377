/* You can add global styles to this file, and also import other style files */
//@import "./node_modules/angular-calendar/css/angular-calendar.css";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

:root {
  --theme-bg-color: rgba(16 18 27 / 40%);
  --border-color: rgba(113 119 144 / 25%);
  --primary: #504DF5;
  --primary-light: #ECECFE;
  --inactive-color: rgb(113 119 144 / 78%);
  --body-font: "Poppins", sans-serif;
  --hover-menu-bg: rgba(12 15 25 / 30%);
  --content-title-color: #999ba5;
  --content-bg: rgb(146 151 179 / 13%);
  --button-inactive: rgb(249 250 251 / 55%);
  --dropdown-bg: #21242d;
  --dropdown-hover: rgb(42 46 60);
  --popup-bg: rgb(22 25 37);
  --search-bg: #14162b;
  --overlay-bg: rgba(36, 39, 59, 0.3);
  --scrollbar-bg: rgb(1 2 3 / 40%);
}

.br-10 {
  border-radius: 10px;
}

.sui-dy-bg {
  background-color: #ffffff;
}

.sui-dy-bg-light {
  background-color: var(--primary-light);
}

.menu-link:hover .sui-fill {
  fill: var(--primary);
}

.is-active .sui-fill {
  fill: var(--primary);
}

.mat-mdc-dialog-surface {
  padding: 24px !important;
}

.orgchart {
  background-image: none;
}

.orgchart .node {
  width: 230px;

  .content {
    font-weight: 600;
  }
}
